import axios from 'axios';
// import Nprogress from 'nprogress'
// import 'nprogress/nprogress.css'
import { ElMessage } from 'element-plus';
import { NO_REDIRECT_WHITE_LIST } from '@/config';
import { getToken, removeToken } from '@/utils/userAuth';

const http = axios.create({
  baseURL: window.VUE_APP_URL,
  timeout: 300 * 1000, // 请求超时时间设置为300秒
});

const NETWORK_ERROR = '网络错误，请联系开发人员';
/**
 * 请求拦截器
 */
http.interceptors.request.use(
  (reqData) => {
    let req = reqData as any;

    if (req && req.url && NO_REDIRECT_WHITE_LIST.indexOf(req.url) == -1) {
      req.headers['Authorization'] = 'Bearer ' + getToken();
    }
    if (!req.headers['Content-Type']) {
      req.headers['Content-Type'] = 'application/json;charset=utf-8';
    }
    // Nprogress.start()
    return req;
  },
  (error) => {
    // Nprogress.done()
    return Promise.reject(error);
  }
);

/**
 * 响应拦截器
 */
http.interceptors.response.use(function (res) {
  // Nprogress.done()
  if (res.status == 200) {
    if (res.data && res.data.code) {
      let responseData = res.data;
      if (responseData.code == 401) {
        removeToken();
        setTimeout(() => {
          location.reload();
        }, 50);
        ElMessage.error('认证失败，请重新登录!!!');
      } else return res.data;
    }
  } else {
    ElMessage.error(NETWORK_ERROR);
    return Promise.reject(NETWORK_ERROR);
  }
});

export default http;
