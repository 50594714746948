/* 路由配置文件 index.ts */

import { createWebHashHistory, createRouter } from 'vue-router';
import { getToken } from '@/utils/userAuth';
// 定义路由配置
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home.vue'),
    meta: {
      title: '首页',
      noCache: true,
      affix: true,
    },
  },
  {
    path: '/extend',
    name: 'extend',
    component: () => import('@/views/extend.vue'),
    meta: {
      title: '智能量算',
      noCache: true,
      affix: true,
    },
  },
];

// 创建路由实例
const router = createRouter({
  history: createWebHashHistory(), // 导航历史记录模式
  routes,
});
// 路由全局前置守卫
const whiteList = ['/', 'Home'];
// 路由全局前置守卫
router.beforeEach((to, from, next) => {
  //   // todo 查询是否有token
  if (to.meta.title) {
    document.title = ('草图云智能系统-' + to.meta.title) as string;
  }
  let toDemo = to as any;
  if (!whiteList.includes(toDemo.name) && !getToken()) {
    // 如果用户未登录且尝试访问非登录页面，重定向到登录页
    next({ name: 'Home' });
  } else {
    // 否则，继续正常的导航流程
    next();
  }
});
// 导出实例
export default router;
