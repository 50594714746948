<template>
  <div class="db-input">
    <div
      v-if="!editMode"
      class="db-value"
      @dblclick="changeInput"
      :style="{'background':bg}"
    >
      <div class="scroll-y">
        {{inputVal}}
      </div>
    </div>
    <el-input
      v-else
      v-model="inputVal"
      ref="dbInput"
      :placeholder="placeholder"
      @input="changeValue"
      @keydown.enter="blurInput"
      @blur="blurInput"
      class="db-values"
    ></el-input>

  </div>

</template>
  <script setup>
import { ref, defineProps, defineEmits, nextTick, onBeforeUnmount, watch } from 'vue'
const emit = defineEmits(['update:modelValue', 'blur'])
const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: 'num'
  },
  bg: {
    type: String,
    default: 'transpranet'
  }
})
let editMode = ref(false),
  dbInput = ref(null),
  inputVal = ref('')
function changeInput () {
  if (props.disabled) return
  editMode.value = true
  nextTick(() => {
    setTimeout(() => {
      dbInput.value.focus()
    }, 50);
  })

}


function changeValue () {
  // 验证输入
  if (props.type && props.type.indexOf('num') > -1) {
    // 获取输入框的值
    let inputValue = inputVal.value;

    // 如果输入是负号或小数点，允许继续
    if (inputValue === '-' || inputValue === '.' ||
      (inputValue.includes('-') && inputValue.indexOf('-') === 0) ||
      (inputValue.includes('.') && inputValue.indexOf('.') === inputValue.length - 1)) {
      return;
    }

    // 1. 处理前导零：去掉除数字本身以外的前导零
    inputValue = inputValue.replace(/^(-?)0+(\d)/, '$1$2');

    // 2. 如果小数点前面没有数字且是负数，补充0
    if (inputValue.indexOf('.') === 0) {
      inputValue = '0' + inputValue;
    } else if (inputValue === '-') {
      inputValue = '0';  // 只有负号时，补充0
    }

    // 3. 如果有小数点，但小数点后没有数字，自动补充 `0`
    if (inputValue.indexOf('.') !== -1 && inputValue.split('.')[1] === '') {
      inputValue += '0';
    }
    // 4. 限制小数点后最多两位
    let decimalIndex = inputValue.indexOf('.');
    if (decimalIndex !== -1) {
      inputValue = inputValue.slice(0, decimalIndex + 3);  // 保留小数点后最多两位
    }

    // 5. 如果有多个小数点，删除最后一个字符
    if ((inputValue.match(/\./g) || []).length > 1) {
      inputValue = inputValue.slice(0, -1);
    }

    // 6. 移除多余的小数点（如果没有数字）
    if (inputValue.indexOf('.') !== -1 && inputValue.match(/\.\d+$/) === null) {
      inputValue = inputValue.replace(/\.$/, ''); // 去除末尾的点
    }
    if (inputValue.indexOf('-.') !== -1) {
      inputValue = inputValue.slice(0, 1);
    }
    // 7. 确保输入是有效的数字格式
    if (!/^-?\d*\.?\d*$/.test(inputValue)) {
      // 如果输入不合法，恢复到有效状态（去掉最后一个字符）
      inputValue = inputValue.slice(0, -1);
    }

    // 更新输入框的值
    inputVal.value = inputValue * 1;

  }
}

function blurInput () {
  editMode.value = false
  if (inputVal.value != props.modelValue && (inputVal.value || inputVal.value == 0)) {
    emit('update:modelValue', inputVal.value);
    setTimeout(() => {
      emit('blur')
    }, 200);
  }


}
/**
 * 整型
 */
function checkGreaterZero (value) {

  if (!value) return
  // 使用正则表达式匹配和/或替换非法字符
  return value.replace(/[^\-?\d.]/g, "")
}
watch(
  () => props.modelValue,
  (val, oldVal) => {
    inputVal.value = ''
    if (val == 0) inputVal.value = 0
    else if ((val || val == 0) && oldVal != val) {
      inputVal.value = JSON.parse(JSON.stringify(val))
    }
  },
  {
    immediate: true
  }
)
onBeforeUnmount(() => {
  editMode.value = false
})
  </script>
  <style lang="less" scoped>
.db-input {
  width: 100%;
  height: 32px;
  .db-value {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0px 2px 0 11px;
    border-radius: 4px;
    .scroll-y {
      height: auto;
      max-height: 100%;
    }
  }
  .db-values {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: none;
    padding: 1px 11px;
    box-sizing: border-box;
    outline: none;
    color: #fff;
    background: @main-bg;
    :deep(.el-input__wrapper) {
      background: transparent !important;
      box-shadow: none !important;
      border: none !important;
      padding: 1px 0 !important;
    }
    :deep(.el-input__inner) {
      color: #fff !important;
    }
  }
}
</style>