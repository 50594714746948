import Cookies from 'js-cookie';
import { TokenKey } from '@/config';
import { store } from '@/store/index';
/**
 * 获取token
 */
export function getToken() {
  return Cookies.get(TokenKey);
}
/**
 * 设置token
 * @param   String  token   传入的token
 * @param   String  rememberMe   是否需要设置toekn过期时间
 * @return  Boolean
 */
export function setToken(token: string) {
  removeToken();
  return Cookies.set(TokenKey, token);
  // if (rememberMe) {
  //   return Cookies.set(TokenKey, token, { expires: tokenCookieExpires })
  // } else return Cookies.set(TokenKey, token)
}
/**
 * 删除token
 */
export function removeToken() {
  removeUserInfo();

  store.commit('setUserInfo', '');
  return Cookies.remove(TokenKey);
}

/**
 * 获取用户
 */
export function getUserInfo() {
  return Cookies.get('userInfo');
}
/**
 * 设置用户
 * @param   userInfo  用户信息
 * @return  Boolean
 */
export function setUserInfo(userInfo: any) {
  if (!userInfo) return;
  removeUserInfo();
  let { user, roles } = userInfo as any;
  let datas = {
    ...user,
    roles,
  };

  Cookies.set('userInfo', JSON.stringify(datas));
}
/**
 * 删除用户
 */
export function removeUserInfo() {
  return Cookies.remove('userInfo');
}
