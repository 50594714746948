var colorF = '#f5f7fa';

/**
 * 判断是否是bylayer
 * @param val
 */
export function judeBylayer(val: string) {
  val;
  return true;
}
//10进制转16进制
export function rgbTenToSix(color: String) {
  return color.replace(/0x/gi, '#');
}
// 16进制转10进制
export function rgbSixToTen(color: String) {
  return color.replace(/#/gi, '0x');
}
// 判断颜色值是否相等
export function judgeColor(color: String, color1: String) {
  if (rgbTenToSix(color).toUpperCase() == rgbTenToSix(color1).toUpperCase())
    return true;
  else return false;
}
//转换颜色  把rgb 转成 16进制 #
export function rgbToHex(red: number, green: number, blue: number) {
  const toHex = (color: number) => {
    const hex = color.toString(16);
    return hex.length == 1 ? '0' + hex : hex;
  };
  return '#' + toHex(red) + toHex(green) + toHex(blue);
}

export function numToFix(val: any) {
  let fixNum = 2;
  if (val == 0) return val;
  else if (val && typeof val == 'string' && val.indexOf('多种') > -1)
    return val;
  else if (val && Number(val)) return Number(val).toFixed(fixNum);
  return 0;
}
