import request from '@/utils/requestUtil';
/**
 * 获取用户配置
 * @returns
 */
export function getUserConfig() {
  return request({
    url: '/system/SysUserConfig/byUser',
    method: 'get',
  });
}
