/**
 * 请求成功状态码
 */
export const SUCCESS_CODE = 0

/**
 * 请求contentType
 */
export const CONTENT_TYPE = 'application/json'

/**
 * 请求超时时间
 */
export const REQUEST_TIMEOUT = 60000

/**
 * 不重定向白名单
 */
export const NO_REDIRECT_WHITE_LIST = ['/login','/captchaImage']

/**
 * 不重置路由白名单
 */
export const NO_RESET_WHITE_LIST = ['Redirect', 'Login', 'NoFind', 'Root']
/**
 * @description 记住密码状态下的token在Cookie中存储的天数，默认1天
 */
export const tokenCookieExpires = 1
/**
 * @description TokenKey存储的字段
 */
export const TokenKey = 'CAD-TOEKN'
