<template>
  <el-dialog
    :modelValue="modelValue"
    :draggable="true"
    :modal="false"
    :close-on-click-modal="false"
    align-center
    append-to-body
    class="custom-dialog"
    :width="width"
    :modal-class="!isMask ? 'custom-dialog-wrapper' : ''"
    @close="cancelDialog"
  >
    <template #header>
      <div class="custom-dialog-title">
        <!-- <svgIcon
          name='cad'
          width="24"
          height="24"
        ></svgIcon> -->
        <img
          src="~@/assets/images/favicon.jpg"
          style="width:42px;height:42px;"
          alt=""
        >
        <div class="dialog-title">{{title}}</div>
      </div>
    </template>
    <div class="dialog-main">
      <slot name="main"></slot>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <slot name="footer">
          <el-button
            type="primary"
            @click.stop="sureDialog"
          >
            确定
          </el-button>
          <el-button
            color="#2e3440"
            @click.stop="cancelDialog"
          >关闭</el-button>
        </slot>

      </div>
    </template>
  </el-dialog>
</template>
<script  setup>
import { ref, defineProps, defineEmits, defineExpose } from 'vue'
// 'update:modelValue', 修改动态绑定 -emit('update:modelValue',true)
const emit = defineEmits(['update:modelValue', 'sureDialog', 'cancelDialog'])
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: '标题'
  },
  width: {
    type: String,
    default: '350px'
  },
  isMask: {
    type: Boolean,
    default: true
  },
  haveSure: {
    type: Boolean,
    default: true
  }
})
props
/**
 * 打开弹框
 */
function openDialog (data) {
  emit('update:modelValue', true)
}
/**
 * 弹框确定
 */
function sureDialog () {
  emit('update:modelValue', false)
  emit('sureDialog')
}
/**
 * 关闭弹框
 */
function cancelDialog () {
  emit('update:modelValue', false)
  emit('cancelDialog')
}
defineExpose({
  openDialog
})
</script>
<style lang="less">
.custom-dialog-wrapper {
  pointer-events: none;
}
.custom-dialog {
  padding: 8px !important;
  max-width: 800px;
  background: @main-bg;
  pointer-events: painted;
  .custom-dialog-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: @colorF;
    .dialog-title {
      margin-left: 8px;
      font-size: 16px;
    }
  }
  .dialog-main {
    width: 100%;
    min-height: 150px;
    padding: 16px 16px 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: @second-bg;
    color: @colorF;
    box-sizing: border-box;
  }
  .el-dialog__footer {
    padding: 8px 12px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    background: @second-bg;
  }
}
</style>